
import { Component, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
import { emptyToLine } from "@/utils/common";
import dayjs from "dayjs";
import { Pageniation as Table } from "@/utils/decorator";
import t from "@common/src/i18n/t";
const base = namespace("base");
const learning = namespace("learning");
@Component({
  components: {}
})
@Table("loadListData")
export default class InteractionView extends Vue {
  @base.Action getDictionaryList;
  // 学习
  @learning.Action getLearnInvitationDetail;
  @learning.Action searchInvitationCommentPage;
  @learning.Action thumbsUpLearnInvitation;
  @learning.Action deleteInvitationComment;
  @learning.Action createInvitationComment;

  emptyToLine = emptyToLine;
  dayjs = dayjs;
  isShowDelete: any = false;
  currentItemCode: any = "";
  contentDetail: any = {};
  commentList: any = [];
  commentForm: any = {
    anonymousPublish: "false",
    invitationComment: ""
  };
  get breadData() {
    return [
      { name: t("v210801.interactive-qa"), path: "/learning/interaction-list" },
      {
        name: t("v210831.post-body")
      }
    ];
  }
  created() {
    this.init();
  }
  get id() {
    return this.$route.params.id;
  }
  init() {
    this.getContentDetail();
    this.loadListData();
    setTimeout(() => {
      if (this.$route.query.comment) {
        this.scrollToView("#mycomment");
      }
    }, 300);
  }
  // 帖子详情
  getContentDetail() {
    this.getLearnInvitationDetail({
      invitationCode: this.id
    }).then(data => {
      this.contentDetail = data.data;
    });
  }
  // 帖子评论列表
  @Table.LoadDataMethod()
  loadListData() {
    return this.searchInvitationCommentPage({
      invitationCode: this.id,
      pageNum: this.mix_pageNum,
      pageSize: this.mix_pageSize
    }).then(data => {
      this.commentList = data.data.list;
      return data;
    });
  }
  scrollToView(id) {
    if (id === "#comment") {
      this.$nextTick(() => {
        (this as any).$refs.textarea.focus();
      });
    }
    window.location.href = id;
  }
  // 点赞帖子
  handlePraise() {
    if (this.contentDetail.thumbsUpBySelf === "true") {
      this.$message.warning(t("v210831.you-like-it"));
      return;
    }
    this.thumbsUpLearnInvitation({
      invitationCode: this.contentDetail.invitationCode
    }).then(data => {
      this.$message.success(t("v210831.praise-succeeded"));
      this.contentDetail.thumbsUpBySelf = "true";
      this.contentDetail.thumbsUpNumber = this.contentDetail.thumbsUpNumber * 1 + 1;
    });
  }
  // 发布评论
  handleReleaseComment() {
    if (this.commentForm.invitationComment === "") {
      this.$message.warning(t("v210831.please-enter-comments"));
      return;
    }
    this.createInvitationComment({
      ...this.commentForm,
      invitationCode: this.id,
      invitationTitle: this.contentDetail.invitationTitle
    }).then(data => {
      this.$message.success(t("v210831.comment-success"));
      this.commentForm.invitationComment = "";
      this.loadListData();
    });
  }
  // 删除评论按钮
  clickDelete(item) {
    this.isShowDelete = true;
    this.currentItemCode = item.commentCode;
  }
  // 删除评论
  handleDelete() {
    this.isShowDelete = false;
    this.deleteInvitationComment({
      commentCode: this.currentItemCode
    }).then(data => {
      this.$message.success(t("v210831.comments-deleted-successfully"));
      this.loadListData();
    });
  }
}
